import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/reevesdotnl/reevesdotnl/src/templates/pdf-view.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PublishDate = makeShortcode("PublishDate");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The meeting dedicated to the East Africa Margin, held at the Geological Society
in London in April  2016, has led to a dedicated volume of the journal Petroleum
Geoscience. The contribution by  Colin Reeves is entitled The development of the
East African margin during Jurassic and Lower Cretaceous times: a perspective
from global tectonics. This paper was released recently online  under the doi
`}<a parentName="p" {...{
        "href": "http://doi.org/10.1144/petgeo2017-021"
      }}>{`http://doi.org/10.1144/petgeo2017-021`}</a>{`.`}</p>
    <p>{`The paper includes all the Euler rotation parameters for the reconstruction
model, including  revisions made up to October 2017, model CR17AAHH. This model
is illustrated by the animation  accessible on the Gondwana page of this
website. `}</p>
    <p>{`The abstract of the paper appears below where it is followed by a single frame
from the animation at 90 Ma (Turonian). `}</p>
    <PublishDate mdxType="PublishDate" />
    <blockquote>
      <p parentName="blockquote">{`The eastern margin of Africa resulted from the first successful trans-Gondwana
rupture which retraced, in part,  the earlier unsuccessful Karoo rift system.
Widespread volcanism in southern Africa (182 Ma, Toarcian)  presaged
northwest-southeast-directed extension between East Gondwana and West Gondwana
(Africa). Rifting turned progressively north-south in orientation leading
quickly to ocean growth off Somalia  and off central Mozambique while,
elsewhere, strike-slip within the stretched margin came to  predominate. East
Gondwana, including Madagascar, was demonstrably still intact at 151.4 Ma
(M22,  Kimmeridgian) but, as the two large continental fragments disengaged
from each other, pure north-south  movement became possible. After about 140
Ma (Berriasian), East Gondwana itself started to fragment off  Western
Australia but little separation occurred as far west as Madagascar before
Aptian times (126.1  Ma). Nevertheless, the geometry of the Australia-India
opening required that, in the interval 140-120 Ma,  Madagascar-India pursued a
path against Africa different from that of Antarctica. The arcuate Davie
fracture  zone, 1800 km in length, functioned as a pure strike-slip transform
off the Tanzania-Mozambique coast for this  fragment until the early Aptian
demise of the Somali mid-ocean ridge. The active transform east of the
Lebombo in southern Africa, meanwhile, relocated progressively eastwards,
finally to outboard of the  Mozambique Ridge at 136 Ma (Valanginian), leaving
most if not all of the stretched continental crust and its
volcano-sedimentary load attached to Precambrian Africa.`}</p>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      